<template>
  <div
    class="pageContainer app-container studentContainer"
    style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>校内新闻</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin-left: 30px; margin-block: 10px">
      <el-input
        v-model="formData.news_title"
        size="small"
        placeholder="新闻标题"
        style="max-width: 200px; margin-right: 10px; margin-left: 10px"
      />
      <el-date-picker
        v-model="formData.release_time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="max-width: 300px; margin-right: 10px; margin-left: 10px"
      >
      </el-date-picker>

      <el-button
        size="small"
        icon="el-icon-search"
        @click="onSearch"
        plain
        type="primary"
        >查询</el-button
      >
      <el-button
        size="small"
        icon="el-icon-refresh"
        @click="onReset"
        type="warning"
        >重置</el-button
      >
    </el-row>
    <el-table
      stripe
      :data="dataList"
      style="width: 100%; margin-bottom: 20px; color: #000"
      border
      size="mini"
    >
      <el-table-column
        prop="news_title"
        label="新闻标题"
        min-width="1000"
      ></el-table-column>

      <el-table-column
        prop="release_time"
        label="发布时间"
        min-width="120"
      ></el-table-column>
      <el-table-column prop="type" label="来源" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '1'">首页爬取</span>
          <span v-if="scope.row.type == '2'">后台添加</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <div class="flexStart">
              <el-button
                type="text"
                size="small"
                @click="findOne(scope.row)"
                style="margin: 0 !important"
                >详情
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新闻详情" :visible.sync="dialogVisible" width="800px">
      <div class="newsDetail" v-if="row" v-html="row.detail"></div>
    </el-dialog>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      row: null,
      dialogVisible: false,
      count: 0,
      current_page: 1,
      per_page: 20,
      activeName: "td",
      dataList: [],
      formData: {},
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
        .post("api/sys_news_list", {
          page: this.current_page,
          pagesize: this.per_page,
          type: 1,
          news_title: this.formData.news_title,
          release_time: this.formData.release_time,
        })
        .then((res) => {
          this.dataList = res.data.data;
          this.count = res.data.page.count;
          this.current_page = res.data.page.current_page;
          this.per_page = res.data.page.per_page;
        });
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    findOne(e) {
      this.row = e;
      this.dialogVisible = true;
    },
  },
};
</script>
<style >
.newsDetail img {
  width: 100% !important;
  height: auto !important;
}
</style>


